import { Component, Directive, ElementRef, HostListener, ViewChild } from '@angular/core';
import { OnboardService } from '../../services/onboard.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ToastService } from '../../shared/toast.service';
import { FileUpload } from 'primeng/fileupload';
import { RazorpayService } from '../../shared/razorpay.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Directive({
  selector: '[appAadhaarSpacing]'
})
export class AadhaarSpacingDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedValue = inputValue.replace(/(\d{4})/g, '$1 '); // Add space after every 4 digits
    event.target.value = formattedValue.trim(); // Trim any trailing spaces
  }
}

@Directive({
  selector: '[appAutoCapitalize]'
})
export class AutoCapitalizeDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    this.el.nativeElement.value = event.target.value.toUpperCase();
  }
}

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrl: './onboard.component.scss',
})
export class OnboardComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSpinner: boolean = false;
  showAadhaarModal: boolean = false;
  showNewApplicationModal: boolean = false;
  showMap: boolean = false;
  aadhaarInitData: any
  activeIndex = 0;
  user: any;
  onboardingDetails: any;
  activeApplication: any;
  activeApplicationId: any;
  outletApplications: any[] = [];
  activeOnboardingStep = 0;
  expandedRows: any = {};
  steps = [
    { title: 'Become an AAharian', subtitle: 'Sign up as an official outlet owner with AAhaar.' },
    { title: 'Owner Details', subtitle: 'Enter your personal contact information.' },
    { title: 'Owner Verification', subtitle: 'Verify your identity to ensure secure access and eKYC.' },
    { title: 'Outlet Details', subtitle: 'Provide details about your outlet location, category, and more.' },
    { title: 'Outlet Verification', subtitle: 'Confirm your outlet’s details for approval and documents verification.' },
    { title: 'Review and Pay', subtitle: 'Review your information and complete the payment process to finalize your registration.' }
  ];
  userDetailsForm: FormGroup;
  userVerificationForm: FormGroup;
  outletDetailsForm: FormGroup;
  suggestions: string[] = ['Burger', 'Sandwich', 'Mocktail', 'ThickShake'];
  filteredSuggestions: string[] = [];
  locations: any[] = [];
  localities: any[] = [];
  locationOptions: any[] = [];
  localityOptions: any[] = [];
  selectedLocation: string = '';
  selectedLocality: string = '';
  tacValue: boolean = false;
  showPaymentModal: boolean = false;
  discountCoupon: string = '';
  selectedOutletReview: any;
  outletReview: any;
  @ViewChild('gstUpload') gstUpload: FileUpload | any; // Get reference to FileUpload
  @ViewChild('fssaiUpload') fssaiUpload: FileUpload | any; // Get reference to FileUpload
  @ViewChild('udyamUpload') udyamUpload: FileUpload | any; // Get reference to FileUpload
  @ViewChild('applicationPreview', { static: false }) applicationPreview!: ElementRef;


  constructor(private onboardService: OnboardService,
    private fireStorage: AngularFireStorage, private router: Router, private fb: FormBuilder, private functions: AngularFireFunctions, private toastService: ToastService, private razorpayService: RazorpayService) {
    // this.callMyFunction();
    this.getScreenSize();
    this.userDetailsForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]]
    });
    this.userVerificationForm = this.fb.group({
      aadhaarNumber: ['', [Validators.required, Validators.pattern(/^\d{4}\s\d{4}\s\d{4}$/)]],
      aadhaarOtp: ['', Validators.required],
      panNumber: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]{5}\d{4}[a-zA-Z]$/)]],
      bankAccNumber: ['', Validators.required],
      bankIfscNumber: ['', Validators.required],
    });
    this.outletDetailsForm = this.fb.group({
      outletName: ['', Validators.required],
      outletCategory: ['', Validators.required],
      outletLocation: ['', Validators.required],
      outletLocality: ['', Validators.required],
      outletLongitude: ['', Validators.required],
      outletLatitude: ['', Validators.required],
    })
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.updateStatus();
        this.getLocations();
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
  }

  onActiveStepChange(newActiveStep: number) {
    this.activeOnboardingStep = newActiveStep
  }

  async saveCurrentStep() {
    this.showSpinner = true;
    if (this.activeApplication?.currentStep == 2) {
      var obj = {
        firstName: this.userDetailsForm.value.firstName,
        lastName: this.userDetailsForm.value.lastName,
        // email: this.userDetailsForm.value.email,
        mobile: this.userDetailsForm.value.mobileNumber
      }
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, obj, indexVal).then((res) => {
        this.updateStatus();
        this.showSpinner = false;
      })
    } else if (this.activeApplication?.currentStep == 3) {
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, null, indexVal).then((res) => {
        this.updateStatus();
        this.showSpinner = false;
      })
    } else if (this.activeApplication?.currentStep == 4) {
      var outletObj = {
        outletName: this.outletDetailsForm.value.outletName,
        outletCategory: this.outletDetailsForm.value.outletCategory,
        outletLocation: {
          outletLongitude: this.outletDetailsForm.value.outletLongitude,
          outletLatitude: this.outletDetailsForm.value.outletLatitude,
        }
      }
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, outletObj, indexVal).then((res) => {
        this.updateStatus();
        this.showSpinner = false;
      })
    } else if (this.activeApplication?.currentStep == 5) {
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, null, indexVal, 'all').then((res) => {
        this.updateStatus();
        this.showSpinner = false;
      });
    } else if (this.activeApplication?.currentStep == 6) {
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, null, indexVal).then((res) => {
        this.updateStatus();
        this.showSpinner = false;
      });
    }
  }

  updateStatus() {
    this.showSpinner = true;
    this.onboardService.getOnboardingDetails(this.user.uid).subscribe((res) => {
      this.onboardingDetails = res.data();
      this.activeApplication = this.onboardingDetails.openOutletApplications[0]
      var obj = {
        name: this.onboardingDetails.openOutletApplications[0].applicationId
      }
      this.activeApplicationId = obj;
      console.warn(this.activeApplication);

      this.onboardingDetails.openOutletApplications.forEach((application: any) => {
        this.outletApplications.push({
          name: application.applicationId
        });
      });
      this.showSpinner = false;
      this.outletApplications = [
        ...this.outletApplications,
        { name: 'Register New Outlet', value: 'register_new_outlet' } // Add a unique value for identification
      ];
      this.userDetailsForm.get('email')?.setValue(this.onboardingDetails.email);
      this.userDetailsForm.get('email')?.disable();
      if (this.onboardingDetails.isAadhaarVerified) {
        this.userVerificationForm.get('aadhaarNumber')?.setValue('Already Verified');
        this.userVerificationForm.get('aadhaarNumber')?.disable();
      }
      if (this.onboardingDetails.isPanVerified) {
        this.userVerificationForm.get('panNumber')?.setValue('Already Verified');
        this.userVerificationForm.get('panNumber')?.disable();
      }
      if (this.onboardingDetails.isBankVerified) {
        this.userVerificationForm.get('bankAccNumber')?.setValue('Already Verified');
        this.userVerificationForm.get('bankAccNumber')?.disable();
        this.userVerificationForm.get('bankIfscNumber')?.setValue('Already Verified');
        this.userVerificationForm.get('bankIfscNumber')?.disable();
      }
      // this.activeOnboardingStep = this.onboardingDetails.openOutletApplications[0].currentStep
    }, err => {
      this.showSpinner = false;
      console.warn(err);
    })
  }

  initAadhaarOtp() {
    this.showSpinner = true;
    this.onboardService.sendAadhaarOtp(this.removeExtraSpaces(this.userVerificationForm?.value.aadhaarNumber)).subscribe((res => {
      console.warn(res);
      this.showSpinner = false;
      this.aadhaarInitData = res;
      if (this.aadhaarInitData.status_code === 500) {
        this.toastService.showError('Error generating OTP \n Please try again after some time')
      } else {
        this.showAadhaarModal = true;
      }
    }))
  }

  initPanOtp() {
    this.showSpinner = true;
    this.onboardService.sendPanOtp(this.userVerificationForm?.value.panNumber).subscribe(((res: any) => {
      this.showSpinner = false;
      if (res.status === 'success') {
        this.toastService.showSuccess("PAN verified successfully")
        this.onboardService.savePanData(this.user.uid, res).then((res) => {
          this.updateStatus()
          this.showSpinner = false;
        }, err => {
          this.showSpinner = false;
        })
      } else {
        this.toastService.showError('Error generating OTP \n Please try again after some time')
      }
    }))
  }

  initBankVerification() {
    this.showSpinner = true;
    this.onboardService.verifyBankAcc(this.userVerificationForm?.value.bankAccNumber, this.userVerificationForm?.value.bankIfscNumber).subscribe((res => {
      this.showSpinner = false;
      this.aadhaarInitData = res;
      if (this.aadhaarInitData.status === 'success') {
        this.toastService.showSuccess("Bank Account verified successfully")
        var obj = {
          bankAccNumber: this.userVerificationForm?.value.bankAccNumber,
          bankIFSC: this.userVerificationForm?.value.bankIfscNumber
        }
        this.onboardService.saveBankAccData(this.user.uid, res, obj).then((res) => {
          this.showSpinner = false;
          this.updateStatus()
        }, err => {
          this.showSpinner = false;
        })
      } else {
        this.toastService.showError('Error generating OTP \n Please try again after some time')
      }
    }))
  }

  verifyAadhaarOtp() {
    this.showSpinner = true;
    this.onboardService.verifyAadhaarOtp(this.aadhaarInitData.request_id, this.userVerificationForm.value.aadhaarOtp).subscribe((res) => {
      this.onboardService.saveAadhaarData(this.user.uid, res).then((res) => {
        this.showSpinner = false;
        this.showAadhaarModal = false;
        this.updateStatus();
      }, err => {
        this.showSpinner = false;
        console.warn(err);
      })
    })
  }

  handleDropdownChange(event: any) {
    if (event.value.value === 'register_new_outlet') {
      this.toggleNewApplicationModal();
    } else {
      this.activeApplicationId = event.value;
      const indexVal = this.outletApplications.findIndex(application => application.name === event.value.name);
      this.activeApplication = this.onboardingDetails.openOutletApplications[indexVal]
    }
  }

  toggleNewApplicationModal() {
    this.showNewApplicationModal = !this.showNewApplicationModal
    if (!this.showNewApplicationModal) {
      var obj = {
        name: this.onboardingDetails.openOutletApplications[0].applicationId
      }
      this.activeApplicationId = obj;
    }
  }

  initNewApplication() {
    this.showSpinner = true;
    var obj = {
      applicationId: this.generateApplicationId(this.onboardingDetails.ownerId),
      currentStep: 2
    }
    this.onboardService.addNewApplication(this.user.uid, obj).then((res) => {
      this.toastService.showSuccess('New application has been successfully created');
      this.showSpinner = false;
    }, err => {
      this.toastService.showError("Error while creating a new application. Please try again after some time.")
      this.showSpinner = false;
    },)
  }

  initEmail() {
    this.onboardService.sendEmail(['chabhinav20@gmail.com', 'karish.md20@gmail.com'], 'Welcome Onboard', 'https://firebasestorage.googleapis.com/v0/b/aahaar-qa.appspot.com/o/emailAssets%2Fwelcome.html?alt=media&token=68ddfdfd-275f-4ac8-b881-5639bcaeffb2').subscribe((res) => {
      console.warn(res);
    }, err => {
      console.warn(err);
    })
  }

  removeExtraSpaces(inputString: string): string {
    return inputString.replace(/\s+/g, ''); // Replace one or more consecutive spaces with an empty string
  }

  filterSuggestions(event: any) {
    const query = event.query.toLowerCase();
    this.filteredSuggestions = this.suggestions.filter(suggestion => suggestion.toLowerCase().includes(query));
  }

  async getCurrentLocation() {
    this.showSpinner = true;
    const permission = await navigator.permissions.query({ name: 'geolocation' });
    if (permission.state === 'granted') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.outletDetailsForm.get('outletLatitude')?.setValue(position.coords.latitude);
            this.outletDetailsForm.get('outletLongitude')?.setValue(position.coords.longitude);
            this.showMap = true;
            this.showSpinner = false;
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setTimeout(() => {
              this.getCurrentLocation()
              console.warn('Retrying');
            }, 4000);
          },
          { enableHighAccuracy: true, timeout: 4000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else if (permission.state === 'prompt') {
      const newPermission = await navigator.geolocation.getCurrentPosition(() => {
        console.warn('success');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.outletDetailsForm.get('outletLatitude')?.setValue(position.coords.latitude);
              this.outletDetailsForm.get('outletLongitude')?.setValue(position.coords.longitude);
              this.showMap = true;
              this.showSpinner = false;
            },
            (error) => {
              console.error("Error getting location:", error.message);
              setTimeout(() => {
                this.getCurrentLocation()
                console.warn('Retrying');
              }, 4000);
            },
            { enableHighAccuracy: true, timeout: 4000 }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }

      }
      );
    } else {
      console.error("Location permission denied.");
    }
  }

  updateLocation(newLocation: { lat: number, lng: number }) {
    this.outletDetailsForm.get('outletLatitude')?.setValue(newLocation.lat);
    this.outletDetailsForm.get('outletLongitude')?.setValue(newLocation.lng);
  }

  generateApplicationId(oID: string): string {
    const currentTimestamp = Date.now();
    const timestampString = currentTimestamp.toString();

    // Get 6 random digits from the timestamp and concatenate them
    let sixDigitNumber = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * timestampString.length);
      sixDigitNumber += timestampString.charAt(randomIndex);
    }

    // Parse oID and sixDigitNumber to integers
    const oIDNum = parseInt(oID, 10);
    const sixDigitNum = parseInt(sixDigitNumber, 10);

    // Calculate the sum and ensure it's within 6 digits
    let finalId = oIDNum + sixDigitNum;
    while (finalId >= 1000000) {
      finalId -= 1000000;
    }

    return 'OAP-' + finalId.toString().padStart(3, '0');
  }

  checkEnable() {
    if (this.activeApplication?.currentStep == 2) {
      return !this.userDetailsForm.valid;
    } else if (this.activeApplication?.currentStep == 3) {
      return !(this.onboardingDetails.isAadhaarVerified && this.onboardingDetails.isPanVerified && this.onboardingDetails.isBankVerified);
    } else if (this.activeApplication?.currentStep == 4) {
      return !this.outletDetailsForm.valid;
    }
    if (this.activeApplication?.currentStep == 5) {
      if (this.activeApplication?.isFSSAISubmitted && this.activeApplication?.isUdyamSubmitted) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  async uploadGst() {
    this.showSpinner = true;
    const selectedFile = this.gstUpload.files[0];

    if (selectedFile) {
      const filePath = `onBoarding/${this.user.uid}/outletDocs/gstDoc`; // Build the path
      const uploadTask = this.fireStorage.upload(filePath, selectedFile)
      const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
      var idObj = {
        idURL: url
      }
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, idObj, indexVal, 'gst').then((res) => {
        this.updateStatus();
        this.showSpinner = false;
        this.toastService.showSuccess("Gst Uploaded Successfully")
      })
      console.log("File upload started...");
    } else {
      this.showSpinner = false;
      console.warn('error');
      // this.messageService.add({severity:'warn', summary:'Warning', detail:'No file selected.'});
    }
  }

  async uploadFSSAI() {
    this.showSpinner = true;
    const selectedFile = this.fssaiUpload.files[0];

    if (selectedFile) {
      const filePath = `onBoarding/${this.user.uid}/outletDocs/fssaiDoc`; // Build the path
      const uploadTask = this.fireStorage.upload(filePath, selectedFile)
      const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
      var idObj = {
        idURL: url
      }
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, idObj, indexVal, 'fssai').then((res) => {
        this.updateStatus();
        this.showSpinner = false;
        this.toastService.showSuccess("Food License Uploaded Successfully")
      })
      console.log("File upload started...");
    } else {
      this.showSpinner = false;
      console.warn('error');
      // this.messageService.add({severity:'warn', summary:'Warning', detail:'No file selected.'});
    }
  }

  async uploadUdyam() {
    this.showSpinner = true;
    const selectedFile = this.udyamUpload.files[0];

    if (selectedFile) {
      const filePath = `onBoarding/${this.user.uid}/outletDocs/udyamDoc`; // Build the path
      const uploadTask = this.fireStorage.upload(filePath, selectedFile)
      const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
      var idObj = {
        idURL: url
      }
      const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
      this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, idObj, indexVal, 'udyam').then((res) => {
        this.updateStatus();
        this.showSpinner = false;
        this.toastService.showSuccess("Food License Uploaded Successfully")
      })
      console.log("File upload started...");
    } else {
      this.showSpinner = false;
      console.warn('error');
      // this.messageService.add({severity:'warn', summary:'Warning', detail:'No file selected.'});
    }
  }

  initiatePayment() {
    var obj = {
      name: this.onboardingDetails.firstName + ' ' + this.onboardingDetails.lastName,
      email: this.onboardingDetails.email,
      mobile: '+91' + this.onboardingDetails.mobile
    }
    const indexVal = this.outletApplications.findIndex(application => application.name === this.activeApplicationId.name);
    const amount = 1 * 100; // Amount in currency subunits. Here 1000 means 10.00 INR
    const key = 'rzp_live_0pr1DoN6E38yAo'; // Replace with your actual API key
    this.razorpayService.payWithRazorpay(amount, key, obj)
      .then((success: any) => {
        if (success) {
          this.toggleShowPaymentModal();
          this.toastService.showSuccess("Payment was successful")
          console.log('Payment was successful');
          this.onboardService.updateOnBoardingProcess(this.activeApplication?.currentStep, this.user.uid, obj, indexVal).then((res) => {
            this.updateStatus()
          })
          // Further actions for successful payment
        }
      })
      .catch((error: any) => {
        if (!error) {
          console.log('Payment failed');
          // Further actions for failed payment
        }
      });
  }

  search(event: any) {
    this.suggestions = this.suggestions.filter(suggestion => suggestion.toLowerCase().includes(event.query.toLowerCase()));
  }

  getLocations() {
    this.locations = [];
    this.onboardService.getLocations().subscribe((res: any) => {
      res.forEach((location: any) => {
        this.locations.push(location.data());
      })
      this.locationOptions = this.locations.map(location => ({
        label: location.locationName,
        value: location.locationId
      }));
    })
  }

  onLocationChange(event: any): void {
    const selectedLocationId = event.value;

    // Find the selected location and populate the localities dropdown
    const selectedLocation = this.locations.find(location => location.locationId === selectedLocationId);
    this.selectedLocation = selectedLocation.locationName
    this.localityOptions = selectedLocation
      ? selectedLocation.localities.map((locality: any) => ({
        label: locality.localityName,
        value: locality.localityId
      }))
      : [];

    // Reset the previously selected locality
    this.outletDetailsForm.get('outletLocality')?.reset();
  }

  onLocalityChange(event: any, localityOptions: any): void {
    const selectedLocalityId = event.value;
    const selectedLocality = this.localityOptions.find(locality => locality.value === selectedLocalityId);
    this.selectedLocality = selectedLocality.label
  }

  downloadApplication() {
    const previewElement = this.applicationPreview.nativeElement;

    html2canvas(previewElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, millimeters, A4 size
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * pageWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      pdf.save('application-details.pdf');
    });
  }

  toggleShowPaymentModal() {
    this.showPaymentModal = !this.showPaymentModal;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  ngOnInit() {
    this.selectedOutletReview = [
      {
        email: "keerthiumasai@gmail.com",
        mobile: "8978209262",
        lastName: "keerthi",
        firstName: "uma",
        aadhaarNumber: "358670531433",
        PANNumber: "LZOPS5432E",
        dob: "2004-01-30",
        address: "Amudhala appala swamy coloy",
        PANCategory: "Individual",
        bankAccNumber: "38604387015",
        bankIFSC: "SBIN0016972",
      },
    ];
  }

}
