import { Component } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { OrdersService } from '../../services/orders.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.scss'
})
export class OrderHistoryComponent {

  value: string = '';
  selectedDate: Date = new Date(); 
  historyDetails: any[] = [];
  selectedOrder: any;

  constructor(private ordersService: OrdersService, private router: Router, private authService: AuthService) {

    this.getOrderHistory()
    if(!this.authService.outletDetails) {
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.authService.getOutletDetails(user.uid).subscribe((res: any) => {
            console.warn(res.data());
            if(res.data().approvedOutlets) {
              this.authService.outletDetails = res.data().approvedOutlets[0];
              this.authService.userDetails = {
                userName: res.data().firstName +' '+ res.data().lastName,
                uid: res.data().uid
              }
             
            } else {
              this.router.navigateByUrl('onboard')
            }
          })
        } else {
          // User not logged in or has just logged out.
        }
      });
    }

  }

  getOrderHistory() {
    this.ordersService.getActiveOrders().subscribe((res) => {
      res.forEach((order: any) => {
        this.historyDetails.push(order.data());
      })
    })
    this.ordersService.getPastOrders().subscribe((res) => {
      res.forEach((order: any) => {
        this.historyDetails.push(order.data());
      })
      this.selectedOrder = this.historyDetails[0];
    })
  }

  updateSelectedOrder(order: any) {
    this.selectedOrder = order;
  }
}
