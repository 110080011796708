import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.scss']
})
export class PayoutsComponent implements OnInit {
  activeAction: string | null = null;
  payouts: any[] = [];
  pastPayouts: any[] = [];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  locations: any[] = [];
  selectedLocation: any = null;
  activeItem: any;
  tabMenuItems: any[] = [];
  startTime: any;
  endTime: any;

  constructor(private activatedRoute: ActivatedRoute, private fireStore: AngularFirestore) {
    this.tabMenuItems = [
      { label: 'Active Payouts' },
      { label: 'Past Payouts' },
    ];
    this.activeItem = this.tabMenuItems[0];
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.activeAction = params['action']
        ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
        : undefined;
    });

    this.generateCurrentPayout();
    this.generatePastPayouts();
  }

  generateCurrentPayout(): void {
    const today = moment();
    const startOfWeek = today.clone().startOf('isoWeek');
    const endOfWeek = today.clone().endOf('isoWeek');
    this.startTime = startOfWeek.valueOf();
    this.endTime = endOfWeek.valueOf();
    this.payouts = [
      {
        payoutPeriod: `${startOfWeek.format('DD MMM YYYY')} - ${endOfWeek.format('DD MMM YYYY')}`,
        orders: Math.floor(Math.random() * 50) + 1,
        payoutDate: endOfWeek.clone().add(3, 'days').format('DD MMM YYYY'),
        estimatedPayout: `₹ ${(Math.random() * (5000 - 3000) + 3000).toFixed(2)}`,
        payoutDetails: {
          totalOrders: 0,
          catB: {
            serviceFee: 0
          },
          catC: {
            // serviceFee: 0
          }
        }
      },
    ];
    var count: any = 0;
    var customerPayable: any = 0;
    var serviceFee: any = 0;
    var paymentMechanismFee: any = 0;
    var deliveredOrdersCount: any = 0;
    var unDeliveredOrdersCount: any = 0;
    this.getOrdersBetweenDates(this.startTime, this.endTime).then((orders: any) => {
      count += 1;
      this.payouts[0]['orders'] = orders.length;
      this.payouts[0]['payoutDetails']['totalOrders'] = orders.length;
      orders.forEach((order: any) => {
        if(order.orderStatus == 'delivered') {
          deliveredOrdersCount += 1
        } else {
          unDeliveredOrdersCount += 1
        }
        this.payouts[0]['payoutDetails']['deliveredOrdersCount'] = deliveredOrdersCount;
        this.payouts[0]['payoutDetails']['unDeliveredOrdersCount'] = unDeliveredOrdersCount;

        order.items.forEach((item: any) => {
          customerPayable += item.itemQuantity * item.itemPrice;
        });
        this.payouts[0]['payoutDetails']['customerPayable'] = customerPayable;
        serviceFee = this.payouts[0]['payoutDetails']['catB']['serviceFee'] =
          parseFloat((customerPayable * 0.15).toFixed(2));
        paymentMechanismFee = this.payouts[0]['payoutDetails']['catB']['paymentMechanismFee'] =
          parseFloat((customerPayable * 0.02).toFixed(2));
        this.payouts[0]['payoutDetails']['catC']['taxesOnCatB'] = parseFloat((parseFloat((serviceFee * 0.18).toFixed(2)) + parseFloat((paymentMechanismFee * 0.18).toFixed(2))).toFixed(2))
      })
    }, () => {
    })
  }

  async getOrdersBetweenDates(startTime: number, endTime: number): Promise<any[]> {
    try {
      const querySnapshot = await this.fireStore
        .collection('outlets')
        .doc('Eluru')
        .collection('Sanivarapupeta')
        .doc('ClouY1HCHoV2RrBoMzPw')
        .collection('pastOrders')
        .ref  // Access the reference of the collection
        .where('orderAcceptedAt', '>=', startTime)
        .where('orderAcceptedAt', '<=', endTime)
        .get();

      return querySnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching orders:', error);
      return [];
    }
  }

  // async getOrdersBetweenDates(startTime: number, endTime: number): Promise<any[]> {
  //   try {
  //     const querySnapshot = await this.fireStore
  //       .collection('outlets')
  //       .doc('Eluru')
  //       .collection('Sanivarapupeta')
  //       .doc('ClouY1HCHoV2RrBoMzPw')
  //       .collection('pastOrders')
  //       .where('orderAcceptedAt', '>=', startTime)
  //       .where('orderAcceptedAt', '<=', endTime)
  //       .get();

  //     return querySnapshot.docs.map((doc: any) => doc.data());
  //   } catch (error) {
  //     console.error('Error fetching orders:', error);
  //     return []; // Or handle the error in a way that suits your application
  //   }
  // }

  generatePastPayouts(): void {
    const today = moment();

    const currentStartOfWeek = today.clone().startOf('isoWeek');
    const currentEndOfWeek = today.clone().endOf('isoWeek');

    this.pastPayouts = [];

    for (let i = 1; i <= 3; i++) {

      const startOfWeek = currentStartOfWeek.clone().subtract(i, 'weeks');
      const endOfWeek = currentEndOfWeek.clone().subtract(i, 'weeks');
      const estimatedPayout = this.calculateEstimatedPayout();

      this.pastPayouts.push({
        payoutPeriod: `${startOfWeek.format('DD MMM YYYY')} - ${endOfWeek.format('DD MMM YYYY')}`,
        orders: Math.floor(Math.random() * 50) + 1, // Random order count
        payoutDate: endOfWeek.clone().add(3, 'days').format('DD MMM YYYY'), // Payout is next day of the week ending
        payout: `₹ ${estimatedPayout.toFixed(2)}`,
      });
    }
  }

  calculateEstimatedPayout(): number {
    return Math.random() * (5000 - 2000) + 2000;
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.payoutPeriod] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.payoutPeriod];
  }

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }
}