<div class="mainContainer w-100">
    <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
        (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
    <div class="activeSection" *ngIf="activeItem!.label == 'Active Payouts'">
        <p-table [value]="payouts" dataKey="payoutPeriod" [expandedRowKeys]="expandedRows"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th>Payout Period</th>
                    <th>Orders (up to yesterday)</th>
                    <th>Payout Date</th>
                    <th>Estimated Payout (up to yesterday)</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payouts let-expanded="expanded">
                <tr>
                    <td>
                        <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                            [pRowToggler]="payouts" class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                    </td>
                    <td>{{ payouts.payoutPeriod }}</td>
                    <td>{{ payouts.orders }}</td>
                    <td>{{ payouts.payoutDate }}</td>
                    <td>{{ payouts.payoutDetails.customerPayable - (payouts.payoutDetails.catB?.serviceFee + payouts.payoutDetails.catB?.paymentMechanismFee) - payouts.payoutDetails.catC?.taxesOnCatB }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-download actionIconBtn"></span>
                            <span class="pi pi-eye actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-payouts let-expanded="expanded">
                <tr>
                    <td colspan="6">
                        <div class="recommendField">
                            <p-accordion [multiple]="true" styleClass="w-100">
                                <ng-container>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Total Orders</span>
                                                </div>
                                                <span class="totalOrders">{{payouts.payoutDetails?.totalOrders}}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <span class="deliverHeader">Delivered Orders</span>
                                                <span class="deliverOrders">{{payouts.payoutDetails.deliveredOrdersCount}}</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <span class="deliverHeader">Cancelled/Rejected Orders</span>
                                                <span class="deliverOrders">{{payouts.payoutDetails.unDeliveredOrdersCount}}</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Customer payable (A)</span>
                                                </div>
                                                <span class="grandAmount">₹ {{payouts.payoutDetails.customerPayable}}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Subtotal</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="subGrandAmount">₹ 6,820.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Packaging Charges</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Delivery Charge for restaurants on self
                                                        logistics</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">RTestaurant discount [Promo]</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 710.80</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">restaurant discounts [flat offs,freebies,
                                                        gold & others]</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 47.60</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Delivery Charges Discount</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Brand pack subscription fee</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">GST collected from customers</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 303.08</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Service feess & payment mechanism fees
                                                        (B)</span>
                                                </div>
                                                <span class="totalOrders">₹ {{payouts.payoutDetails.catB?.serviceFee + payouts.payoutDetails.catB?.paymentMechanismFee}}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Services fees</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ {{payouts.payoutDetails.catB?.serviceFee}}</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Payment Mechanism fee</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ {{payouts.payoutDetails.catB?.paymentMechanismFee}}</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <span class="deliverHeader">Brand Loyality Points Redemption</span>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Government Charges (c)</span>
                                                </div>
                                                <span class="totalOrders">₹ {{payouts.payoutDetails.catC?.taxesOnCatB}}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Taxes on services fees & payment mechanism
                                                        fees</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ {{payouts.payoutDetails.catC?.taxesOnCatB}}</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Tax collected at source + TCS IGST
                                                        amount</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">TDS 1940 amount</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span class="totalHeader">Other order level deductions (D)</span>
                                                </div>
                                                <span class="totalOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Investments in growth services &
                                                        miscellaneous deductions (E)</span>
                                                </div>
                                                <span class="totalOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <span class="deliverHeader">Ads (including 18% GST )</span>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Investments in Hyperpure (F)</span>
                                                </div>
                                                <span class="totalOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Hyperpure</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                    <p-accordionTab>
                                        <ng-template pTemplate="icon">
                                            <div style="display: none;"></div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <div
                                                class="accordionHeader d-flex align-items-center justify-content-between">
                                                <div class="totalField d-flex gap-2">
                                                    <span
                                                        class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                    <span class="totalHeader">Total Additions (G)</span>
                                                </div>
                                                <span class="totalOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Cancellation Refund</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">Tip for kitchen staff</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">TDS 194H</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <div class="deliverHeader d-flex gap-2">
                                                    <span class="infoSpell">TDS 194C</span>
                                                    <span
                                                        class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                </div>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                            <div class="deliveredInfo d-flex justify-content-between">
                                                <span class="deliverHeader">Carried Forward pauouts from previous
                                                    weeks</span>
                                                <span class="deliverOrders">₹ 0.00</span>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                </ng-container>
                            </p-accordion>
                            <div class="totalCalcField d-flex justify-content-between">
                                <span class="totalHeader">Estimated Net Payouts(A-B-C-D-E-F+G)</span>
                                <span class="grandAmount">₹ {{payouts.payoutDetails.customerPayable - (payouts.payoutDetails.catB?.serviceFee + payouts.payoutDetails.catB?.paymentMechanismFee) - payouts.payoutDetails.catC?.taxesOnCatB}}</span>
                            </div>

                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="pastSection" *ngIf="activeItem!.label == 'Past Payouts'">
        <div class="sortSection d-flex justify-content-end">
            <div class="p-date d-flex">
                <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="'true'"
                    inputId="icondisplay" />
            </div>
        </div>
        <div class="bodySection">
            <p-table [value]="pastPayouts" dataKey="payoutPeriod" [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"></th>
                        <th>Payout Period</th>
                        <th>Orders</th>
                        <th>Status</th>
                        <th>UTR</th>
                        <th>Payout </th>
                        <th>Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-payouts let-expanded="expanded">
                    <tr>
                        <td>
                            <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                                [pRowToggler]="payouts" class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                        </td>
                        <td>{{ payouts.payoutPeriod }}</td>
                        <td>{{ payouts.orders }}</td>
                        <td>
                            <div class="statusRow">
                                <span class="statusSpell">PAID</span>
                                <span class="statusDate">{{ payouts.payoutDate }}</span>
                            </div>
                        </td>
                        <td>CITIN245990777678</td>
                        <td>{{ payouts.payout }}</td>
                        <td>
                            <div class="d-flex gap-2">
                                <span class="pi pi-download actionIconBtn"></span>
                                <span class="pi pi-eye actionIconBtn"></span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-payouts let-expanded="expanded">
                    <tr>
                        <td colspan="7">
                            <div class="recommendField">
                                <p-accordion [multiple]="true" styleClass="w-100">
                                    <ng-container>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Total Orders</span>
                                                    </div>
                                                    <span class="totalOrders">25</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <span class="deliverHeader">Delivered Orders</span>
                                                    <span class="deliverOrders">25</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <span class="deliverHeader">Cancelled/Rejected Orders</span>
                                                    <span class="deliverOrders">0</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Customer payable (A)</span>
                                                    </div>
                                                    <span class="grandAmount">₹ 6,364.68</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Subtotal</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="subGrandAmount">₹ 6,820.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Packaging Charges</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Delivery Charge for restaurants on self
                                                            logistics</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">RTestaurant discount [Promo]</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 710.80</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">restaurant discounts [flat
                                                            offs,freebies,
                                                            gold & others]</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 47.60</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Delivery Charges Discount</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Brand pack subscription fee</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">GST collected from customers</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 303.08</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Service feess & payment mechanism fees
                                                            (B)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 1,632.54</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Services fees</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 1,515.40</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Payment Mechanism fee</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 117.14</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <span class="deliverHeader">Brand Loyality Points Redemption</span>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Government Charges (c)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 596.86</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Taxes on services fees & payment
                                                            mechanism
                                                            fees</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 293.78</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Tax collected at source + TCS IGST
                                                            amount</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">TDS 1940 amount</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">GST paid by AAhaar on behalf of
                                                            restaurant</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 303.08</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span class="totalHeader">Other order level deductions
                                                            (D)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 596.86</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Investments in growth services &
                                                            miscellaneous deductions (E)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <span class="deliverHeader">Ads (including 18% GST )</span>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Investments in Hyperpure (F)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Hyperpure</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                        <p-accordionTab>
                                            <ng-template pTemplate="icon">
                                                <div style="display: none;"></div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <div
                                                    class="accordionHeader d-flex align-items-center justify-content-between">
                                                    <div class="totalField d-flex gap-2">
                                                        <span
                                                            class="pi pi-chevron-down d-flex justify-content-center align-items-center"></span>
                                                        <span class="totalHeader">Total Additions (G)</span>
                                                    </div>
                                                    <span class="totalOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content" class="d-flex flex-column gap-2">
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Cancellation Refund</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">Tip for kitchen staff</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">TDS 194H</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <div class="deliverHeader d-flex gap-2">
                                                        <span class="infoSpell">TDS 194C</span>
                                                        <span
                                                            class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                                    </div>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                                <div class="deliveredInfo d-flex justify-content-between">
                                                    <span class="deliverHeader">Carried Forward pauouts from previous
                                                        weeks</span>
                                                    <span class="deliverOrders">₹ 0.00</span>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                    </ng-container>
                                </p-accordion>
                                <div class="totalCalcField d-flex justify-content-between">
                                    <span class="totalHeader">Estimated Net Payouts(A-B-C-D-E-F+G)</span>
                                    <span class="grandAmount">₹ 4,135.28</span>
                                </div>

                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>