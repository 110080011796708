<div class="mainContainer">
    <div class="subContainer d-flex flex-column">
        <div class="sortSection d-flex justify-content-between">
            <div class="searchSection d-flex">
                <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" pInputText placeholder="Enter Order Id" />
                </p-iconField>
            </div>
            <div class="d-flex gap-2">
                <div class="p-date d-flex">
                    <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true" dateFormat="dd/mm/yy"
                        inputId="icondisplay" />
                </div>
            </div>
        </div>
        <div class="bodySection w-100 h-100 d-flex ">
            <div class="leftPart d-flex flex-column gap-2">
                <div class="metaHistory d-flex flex-column">
                    <div class="cardPart d-flex justify-content-between gap-4 flex-column"
                        *ngFor="let order of historyDetails" (click)="updateSelectedOrder(order)">
                        <div class="d-flex justify-content-between ">
                            <div class="d-flex flex-column gap-1">
                                <div class="idInfo">Order Id : {{order.customerDetails.orderId.slice(-4)}}</div>
                                <div class="byInfo">By {{order.customerDetails.customerName}}</div>
                                <div class="calenderInfo">{{ order.orderPlacedAt | date:'dd MMM yyyy, hh:mm a' }}</div>
                            </div>
                            <div class="statusPill" [ngClass]="{
                                        'newPill': order.orderStatus === 'new',
                                        'preparingPill': order.orderStatus === 'preparing',
                                        'pickedPill': order.orderStatus === 'pickedUp',
                                        'readyPill': order.orderStatus === 'ready',
                                        'deliveredPill': order.orderStatus === 'delivered'
                                        }">{{order.orderStatus}}</div>
                        </div>
                        <div class="itemInfo d-flex justify-content-between" *ngFor="let item of order.items">
                            <span class="itemMeta">{{item.itemQuantity}}X {{item.itemName}}</span>
                            <span class="priceInfo">₹ {{item.itemPrice}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightPart">
                <div class="historyPanel d-flex flex-column gap-2">
                    <div class="orderDiv d-flex flex-column">
                        <div class="customerDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Customer Details</span>
                            </div>
                            <div class="orderSection d-flex justify-content-between">
                                <div class="notifyOrder d-flex gap-5">
                                    <div class="orderHeader d-flex flex-column gap-2">
                                        <span class="orderSpell">Order Id</span>
                                        <span class="orderSpell">Ordered Time</span>
                                    </div>
                                    <div class="infoDiv d-flex flex-column gap-2">
                                        <span class="infoSpell"> : {{selectedOrder?.customerDetails.orderId}}</span>
                                        <span class="infoSpell"> :{{ selectedOrder?.orderPlacedAt | date:'dd MMM yyyy,
                                            hh:mm a' }}</span>
                                    </div>
                                </div>
                                <div class="notifyOrder d-flex gap-5">
                                    <div class="orderHeader d-flex flex-column gap-2">
                                        <span class="orderSpell">Ordered By</span>
                                        <span class="orderSpell">Address</span>
                                    </div>
                                    <div class="infoDiv d-flex flex-column gap-2">
                                        <span class="infoSpell"> :
                                            {{selectedOrder?.customerDetails?.customerName}}</span>
                                        <span class="infoSpell"> :
                                            {{selectedOrder?.deliveryPartnerDetails?.basicDetails?.address}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="orderDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Cart Details</span>
                            </div>
                            <div class="d-flex flex-column gap-2" *ngFor="let item of selectedOrder?.items">
                                <div class="orderInfo d-flex justify-content-between">
                                    <div class="orderMeta" class="d-flex gap-2">
                                        <span class="itemCount">{{item.itemQuantity}}X </span>
                                        <span class="itemName d-flex justify-content-center align-items-center">
                                            {{item.itemName}}</span>
                                    </div>
                                    <div class="priceInfo">
                                        <span class="priceSpell">₹ {{item.itemPrice}}</span>
                                    </div>
                                </div>
                                <div class="totalDiv d-flex justify-content-between">
                                    <div class="totalCount d-flex gap-2">
                                        <span
                                            class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                                        <span class="totalSpell">Total Bill</span>
                                    </div>
                                    <div class="amountSpell">
                                        <span>₹ 153</span>
                                    </div>
                                </div>
                                <div class="instructionDiv ">
                                    <span class="headerSpell">Delivery Instructions : </span>
                                    <span> Don’t Send cutlery,tissues and straws</span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="deliverDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Deliver Details</span>
                            </div>
                            <div class="notifyOrder d-flex gap-5">
                                <div class="orderHeader d-flex flex-column gap-2">
                                    <span class="orderSpell">Rider Name</span>
                                    <span class="orderSpell">Delivered Time</span>
                                </div>
                                <div class="infoDiv d-flex flex-column gap-2">
                                    <span class="infoSpell"> :
                                        {{selectedOrder?.deliveryPartnerDetails?.basicDetails?.firstName}}
                                        {{selectedOrder?.deliveryPartnerDetails?.basicDetails?.lastName}} </span>
                                    <span class="infoSpell"> : 01: 30 PM</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="emptyState d-flex flex-column align-items-center w-100" *ngIf="historyDetails.length == 0">
            <div class="backSection d-flex gap-2 justify-content-start align-items-center w-100">
                
                <span class="historyIdentity">Order History</span>
            </div>
            <span class="emptyMsg">
                No previous orders
            </span>
        </div> -->
    </div>