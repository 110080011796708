import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  outletsCollection: AngularFirestoreCollection;
  openOrdersCollection: AngularFirestoreCollection;
  usersCollection: AngularFirestoreCollection;
  deliveryPartnersCollection: AngularFirestoreCollection;
  
  constructor(private fireStore: AngularFirestore, private authService: AuthService) {
    this.outletsCollection = fireStore.collection('outlets');
    this.openOrdersCollection = fireStore.collection('openOrders');
    this.usersCollection = fireStore.collection('users');
    this.deliveryPartnersCollection = fireStore.collection('deliveryPartners');
  }

  acceptOrder(id: any, cookingTime: any){
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('activeOrders').doc(id).update({orderStatus: 'preparing', orderAcceptedAt: Date.now(), expectedFoodReadyBy: Date.now() + (cookingTime * 60 * 1000)})
  }

  getActiveOrders() {
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('activeOrders').get();
  }

  getPastOrders() {
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('pastOrders').get();
  }

  markReady(id: any) {
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('activeOrders').doc(id).update({orderStatus: 'ready', orderReadyAt: Date.now(), isFoodReady: true})
  }

  newOpenOrder(obj: any) {
    return this.openOrdersCollection.doc().set(obj)
  }

updateUserObj(id: any, orderId: any, status: any): Promise<void> {
  return this.usersCollection.ref.where('id', '==', id).get()
    .then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        const userDocRef = querySnapshot.docs[0].ref;
        const activeOrdersCollection = userDocRef.collection('activeOrders');

        return activeOrdersCollection.where('orderId', '==', orderId).get()
          .then((orderQuerySnapshot) => {
            if (orderQuerySnapshot.docs.length > 0) {
              const orderDocRef = orderQuerySnapshot.docs[0].ref;
              return orderDocRef.update({ orderStatus: status });
            } else {
              console.log('No order found with orderId:', orderId);
              return Promise.reject('No order found'); 
            }
          });
      } else {
        console.log('No document found with id:', id);
        return Promise.reject('No document found'); 
      }
    })
    .catch(error => {
      console.error('Error fetching documents:', error);
      return Promise.reject(error); 
    });
}
}
