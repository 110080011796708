import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @ViewChild('bgHolder') bgHolder!: ElementRef;
  @ViewChild('rendererContainer', { static: true }) rendererContainer!: ElementRef;
  
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  email: string = '';
  password: string = '';
  showForm: boolean = true;
  showPassword: boolean = false;
  showSpinner: boolean = false;
  isShrinking = true;
  isExpanding = false;
  
  private scene!: THREE.Scene;
  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  private model!: THREE.Object3D;
  private originalRotationY: number = 0;
  private isTrackingMouse: boolean = true;
  
  constructor(private router: Router, private firebaseAuth: AngularFireAuth) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.initScene();
    this.loadGLBModel();
    this.animate();
  }

  ngAfterViewInit(): void {
    var timer = setInterval(() => {
      if(this.bgHolder) {
        clearInterval(timer);
        this.matchCanvasToBgHolder()
      }
    }, );
    window.addEventListener('resize', () => this.matchCanvasToBgHolder());
  }
  

  private matchCanvasToBgHolder(): void {
    const bgHolder = document.querySelector('.bgHolder') as HTMLElement;
    let count = 0;
    var timer = setInterval(() => {
      count ++;
      if(count == 2500) {
        clearInterval(timer);
      } else {
        if (bgHolder) {
          const { width, height } = bgHolder.getBoundingClientRect();
          this.renderer.setSize(width, height);
          this.camera.aspect = width / height;
          this.camera.updateProjectionMatrix();
          this.model.position.set(0, 0, 0)
        }
      }
    }, );
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  navigateToRegister(): void {
    this.isShrinking = false;
    this.isExpanding = true;
    setTimeout(() => this.router.navigateByUrl('register'), 2500);
  }

  navigateToResetPassword(): void {
    this.isShrinking = false;
    this.isExpanding = true;
    setTimeout(() => this.router.navigateByUrl('forgotpassword'), 2500);
  }

  attemptLogin(): void {
    if (this.email && this.password) {
      this.initLoginPartner();
    }
  }

  initLoginPartner(): void {
    this.showSpinner = true;
    this.firebaseAuth.signInWithEmailAndPassword(this.email, this.password)
      .then(userCredential => {
        console.log('User signed in:', userCredential.user);
        this.showSpinner = false;
        this.router.navigateByUrl('home');
      })
      .catch(error => {
        console.error('Error signing in:', error.code, error.message);
      });
  }

  private initScene(): void {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.camera.position.z = 5;

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(0, 1, 1);
    this.scene.add(directionalLight);

    window.addEventListener('mousemove', this.onMouseMove.bind(this));
  }

  private loadGLBModel(): void {
    const loader = new GLTFLoader();
    loader.load(
      '../../../assets/3d/binocular.glb', // Replace with your model path
      (gltf) => {
        this.model = gltf.scene;
        if (this.model) {
          this.model.position.set(0, 0, 0); // Set initial position
          this.model.scale.set(1, 1, 1); // Set initial scale
          this.model.rotation.set(0, 0, 0); // Optionally set initial rotation
          this.scene.add(this.model); // Add the model to the scene
        }
      },
      // (xhr) => console.log(`Model ${(xhr.loaded / xhr.total) * 100}% loaded.`),
      // (error) => console.error('Error loading model:', error)
    );
  }

  private animate(): void {
    requestAnimationFrame(() => this.animate());
    if (this.model) {
      // this.model.rotation.y += 0.01;
    }
    this.renderer.render(this.scene, this.camera);
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.isTrackingMouse) return;

    const rect = this.renderer.domElement.getBoundingClientRect();
    const mouseX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const mouseY = 0;
    const mouseZ = 0.5;
    const vector = new THREE.Vector3(mouseX, mouseY, mouseZ).unproject(this.camera);
    const direction = vector.sub(this.camera.position).normalize();
    const distance = -this.camera.position.z / direction.z;
    const targetPosition = this.camera.position.clone().add(direction.multiplyScalar(distance));
    if (this.model) {
      let rotationY = mouseX * Math.PI;
      rotationY = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, rotationY));
      this.model.rotation.y = rotationY;
    }
  }
  
  onPasswordFocus(): void {
    this.isTrackingMouse = false;
    if (this.model) {
      this.originalRotationY = this.model.rotation.y;
      this.model.rotation.y += (7 * Math.PI) / 6;
    }
  }
  
  onPasswordBlur(): void {
    this.isTrackingMouse = true;
    if (this.model) {
      // Restore the original rotation
      this.model.rotation.y = this.originalRotationY;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.isDesktop = this.screenWidth >= 768;
    this.isMobile = !this.isDesktop;
  }
}
